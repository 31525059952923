<template>
  <auth-content>
    <el-header slot="header" />
    <el-modules-permission-form />
  </auth-content>
</template>

<script>


export default {
  name: 'PermissionsPage',
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.row-permission {
  &:hover {
    background: rgba(black, .05) !important;
  }
}
</style>

<template>
  <auth-content>
    <el-header slot="header">
      <el-header-action v-if="isCurrentWorldOwner" size="xs" slot="actions" icon="add" :label="$t('tribe.list.add')" @click.native="openModal('tribe-world-add')"></el-header-action>
    </el-header>

    <el-filters :filters="filters"/>

    <div v-for="(tribe, index) in tribesFiltered" :key="tribe.id">

      <router-link class="card cursor-pointer" :to="tribe.link" tag="div">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-md-2 col-lg-2 col-xl-1 text-center avatar">
              <el-avatar size="big" v-if="tribe.avatar && tribe.avatar != ''" :src="tribe.avatar" />
            </div>
            <div class="col-md">
              <div class="font-weight-bold">
                {{ tribe.name }}
              </div>
              <div class="mt-1 text-grey">{{ tribe.users_count }}</div>
            </div>
            <div class="col-md">
              <div class="avatar-line">
                <span :key="user.id" v-for="user in tribe.users" class="avatar">
                  <el-avatar :src="user.avatar.thumb" v-tooltip="user.full_name && user.full_name != '' ? user.full_name : user.email"/>
                </span>
              </div>
            </div>
            <div class="col-md text-right">
              <el-table-action
                v-if="isCurrentWorldOwner && tribe.removable"
                @click.prevent.native="tribe_edit_name=tribe.name; edit_tribe_id=tribe.id; openModal('tribe-world-edit')"
                icon="icon-pen6"
                :title="$t('tribe.list.edit_tribe')"
              />
              <el-table-action
                v-if="isCurrentWorldOwner && tribe.removable"
                @click.prevent.native="confirmTribeRemove(tribe.id)"
                icon="icon-bin"
                :title="$t('tribe.list.remove_tribe')"
              />
              <el-table-action
                classes="view-link"
                :to="tribe.link"
                icon="icon-arrow-right8"
              />
            </div>
          </div>
        </div>
      </router-link>

    </div>

    <el-modal size="sm" :title="$t('tribe.list.add')" id="tribe-world-add">
      <form @submit.prevent="addTribe()">
        <div class="form-group">
          <label for="name" class="font-weight-bold label-required">{{ $t('tribe.name') }}</label>
          <input v-model="tribe_add_name" required type="text" class="form-control" id="name" :placeholder="$t('tribe.name')">
        </div>
        <div class="text-center">
          <button v-if="!tribe_loading" type="submit" class="btn btn-success text-white">{{ $t('common.validate') }}</button>
          <div v-if="tribe_loading" class="text-gray pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>

    <el-modal size="sm" :title="$t('tribe.list.edit_tribe')" id="tribe-world-edit">
      <form @submit.prevent="editTribe()">
        <div class="form-group">
          <label for="name" class="font-weight-bold label-required">{{ $t('tribe.name') }}</label>
          <input v-model="tribe_edit_name" required type="text" class="form-control" id="name" :placeholder="$t('tribe.name')">
        </div>
        <div class="text-center">
          <button v-if="!tribe_loading" type="submit" class="btn btn-success text-white">{{ $t('common.update') }}</button>
          <div v-if="tribe_loading" class="text-gray pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>

    <el-modal size="sm" :title="$t('tribe.list.remove_tribe')" id="tribe-world-remove">
      <form @submit.prevent="removeTribe()">
        <div class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_tribe_confirm">
            {{ $t('tribe.list.remove_tribe_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!tribe_loading" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="tribe_loading" class="pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>
  </auth-content>
</template>


<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '../../store'
import {
  WORLD_TRIBES_REQUEST,
  WORLD_TRIBES_STORE,
  WORLD_TRIBES_UPDATE,
  WORLD_TRIBES_REMOVE,
} from '../../store/mutations-types'

export default {
  name: 'WorldTribesList',
  tag: 'world-tribes-list',
  data () {
    return {
      searchValue: '',
      edit_tribe_id: null,
      remove_tribe_id: null,
      remove_tribe_confirm: false,
      tribe_add_name: '',
      tribe_edit_name: '',
      tribe_loading: false,
      tribes: false,
      loading: false,
      columns: {
        name: this.$t('tribe.name'),
        users_count: this.$t('tribe.users_count')
      },
      filters: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_TRIBES_REQUEST, to.params.world).then(tribes => {
      next(vm => {
        vm.tribes = {...tribes}
      })
    })
  },
  created () {
  },
  methods: {
    loadTribes () {
      this.$store.dispatch('world/' + WORLD_TRIBES_REQUEST, this.$route.params.world).then(tribes => {
        this.tribes = tribes
        this.loadFilters()
      })
    },
    loadFilters () {
      let tribes = {...this.formatTribes()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('form.tribe.status'),
          key: 'status',
          options: this.getFilterOptions(tribes, 'status'),
        },
        {
          title: this.$t('form.tribe.position'),
          key: 'position',
          options: this.getFilterOptions(tribes, 'position')
        }
      ])
    },
    addTribe () {
      this.tribe_loading = true
      this.$store.dispatch('world/' + WORLD_TRIBES_STORE, {
        worldId: this.$route.params.world,
        name: this.tribe_add_name
      }).then(data => {
        this.tribe_loading = false
        this.notifSuccess(data)
        this.loadTribes()
        this.closeModal('tribe-world-add')
        this.tribe_add_name = ''
      })
      .catch(error => {
        this.tribe_loading = false
        this.notifError(error)
      })
    },
    editTribe () {
      this.tribe_loading = true
      this.$store.dispatch('world/' + WORLD_TRIBES_UPDATE, {
        worldId: this.$route.params.world,
        tribeId: this.edit_tribe_id,
        name: this.tribe_edit_name
      }).then(data => {
        this.tribe_loading = false
        this.notifSuccess(data)
        this.loadTribes()
        this.closeModal('tribe-world-edit')
        this.edit_tribe_id = null
        this.tribe_edit_name = ''
      })
      .catch(error => {
        this.tribe_loading = false
        this.notifError(error)
      })
    },
    confirmTribeRemove (tribeId) {
      this.remove_tribe_confirm = false
      this.remove_tribe_id = tribeId
      this.openModal('tribe-world-remove');
    },
    removeTribe() {
      if (this.remove_tribe_confirm) {
        this.$store.dispatch('world/' + WORLD_TRIBES_REMOVE, {
          worldId: this.$route.params.world,
          tribeId: this.remove_tribe_id
        }).then(data => {
          this.notifSuccess(data)
          this.loadTribes()
          this.closeModal('tribe-world-remove')
          this.remove_tribe_id = null
          this.remove_tribe_confirm = false
        })
        .catch(error => {
            this.notifError(error)
        })
      }
    },
    formatTribes () {
      let tribes = []

      if (this.tribes) {
        // Get tribes
        _.map(this.tribes, (elem) => {
          let tribe = {
            id: elem.id,
            link: `/world/${this.$route.params.world}/tribes/${elem.id}`,
            name: elem.name,
            users: elem.users,
            removable: elem.removable,
            avatar: elem.slug ? require('@/assets/images/categories/' + elem.slug + '.svg') : elem.avatar.thumb,
            users_count: this.$tc('tribe.list.users_count', elem.users_count, {nb: elem.users_count}),
          }
          tribes.push(tribe);
        })
      }

      return tribes
    }
  },
  computed: {
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    tribesFormatted () {
      return this.formatTribes()
    },
    tribesFiltered () {

      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        return this.applyDataFilters(this.tribesFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.tribesFormatted, this.filters), {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
        ]
      }).search(searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
img.preview {
  object-fit: cover;
  width: 35px;
  height: 35px;
}
</style>

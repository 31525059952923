<template>
  <div>

    <div v-if="invitation && !loading">

      <div class="text-center">
        <img src="@/assets/images/register.svg" class="lutin my-3">
        <div class="font-weight-bold mb-3 py-md-3">
          <el-card-title :title="$t('user.world_invite', { world: invitation.world.name })" />
        </div>


        <form @submit.prevent="register" class="form row justify-content-center align-items-center">
          <div class="col-md-5 text-left">
            <el-alert type="warning" v-if="formError !== false">
              <span v-html="formError"></span>
            </el-alert>
            <label class="font-weight-bold" for="email">{{ $t('user.register.email') }}</label>
            <div class="form-group form-group-feedback form-group-feedback-left">
              <input readonly required autocomplete="email" v-model="email" type="email" id="email" class="form-control" :placeholder="$t('user.register.email')">
              <div class="form-control-feedback">
                <i class="icon-user text-muted"></i>
              </div>
            </div>
            <label class="font-weight-bold" for="email">{{ $t('user.register.choose_password') }}</label>
            <div class="form-group form-group-feedback form-group-feedback-left">
              <input required autocomplete="current-password" v-model="password" type="password" class="form-control" :placeholder="$t('user.register.password')">
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
            </div>
            <div class="text-center">
              <button v-if="$store.state.auth.status != 'loading'" type="submit" class="my-2 px-3 btn btn-success text-white btn-lg">
                <i class="icon-magic-wand mr-2"></i> {{ $t('user.register.register') }}
              </button>
              <div v-if="$store.state.auth.status == 'loading'" class="bg-white p-2">
                <loader />
              </div>
            </div>
          </div>
        </form>

      </div>

    </div>

    <div v-if="loading" class="text-center p-5 text-primary">
       <loader></loader>
    </div>

  </div>
</template>

<script>

import store from '../../store'
import { WORLD_INVITATION_REQUEST, AUTH_REGISTER, AUTH_LOGOUT } from '../../store/mutations-types'

export default {
  name: 'WorldInvitationAction',
  data () {
    return {
      email: '',
      password: '',
      loading: true,
      invitation: null,
      formError: false,
    }
  },
  mounted () {
    this.$store.dispatch('world_invitation/' + WORLD_INVITATION_REQUEST, this.$route.params.token).then(invitation => {
      this.invitation = {...invitation}

      this.email = this.invitation.email

      if (this.isAuth()) {
        if (this.invitation.email === this.currentUserEmail) {
          // the user that was invited is the current user > redirect to home and let him use the notification global message to answer the invitation
          this.$router.push('/')
        } else if (this.invitation.email !== this.currentUserEmail && this.invitation.user_id !== null) {
          // the user that was invited is different than the current user = logout the current to let the invited login
          this.$store.dispatch('auth/' + AUTH_LOGOUT)
            .then(() => {
              this.$router.push('/login')
            })
        } else if (this.invitation.email !== this.currentUserEmail && this.invitation.user_id === null) {
          // the user that was invited is different than the current user = logout the current to let the invited login
          this.$store.dispatch('auth/' + AUTH_LOGOUT)
        }
      } else {
        if (this.invitation.user_id !== null) {
          // the user that was invited have an account
          this.$router.push('/login')
        } else if (this.invitation.user_id !== null) {
          // the user that was invited do not have an account : do nothing let him register
        }

        this.loading = false

      }

    })
    .catch((error) => {
      // Invalid invitation
      this.loading = false
      this.$router.push('/')
      this.notifError(error)
    })
  },
  computed: {
    currentUserEmail () {
       return this.$store.getters['auth/getEmail']
    }
  },
  methods: {
    register () {
      this.formError = false
      const { email, password } = this
      this.$store.dispatch('auth/' + AUTH_REGISTER, { email, password }).then(() => {
        this.$router.push('/')
      })
      .catch((errors) => {
        this.formError = ''
        _.toArray(errors.response.data.errors).forEach( (element, index) => {
          this.formError+= '' + element[0] + '<br/>'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lutin {
  max-width: 220px;
}
</style>

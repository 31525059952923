<template>
  <auth-content>
    <el-header :title="$t('user.list.create_user')" slot="header" :back="true" :favorites="false">
      <div slot="left">
        <el-header-back />
      </div>
    </el-header>
    <form @submit.prevent="createUser">
      <div class="card card-content">
        <div class="card-body">
          <div class="row no-gutters bordered-wide border rounded">
            <label class="type-label cursor-pointer col-md-6 m-0">
              <div class="p-3 d-flex align-items-center">
                <input type="radio" name="type" v-model="user.type" value="internal" class="d-none">
                <i class="icon-radio-checked2 text-success mr-2" v-if="user.type == 'internal'" />
                <i class="icon-radio-unchecked mr-2" v-else />
                <div>
                  <div class="font-weight-bold">{{ $t('common.internal') }}</div>
                  <div>{{ $t('common.internal_desc') }}</div>
                </div>
              </div>
            </label>
            <label class="type-label cursor-pointer col-md-6 m-0 bordered-wide border-left">
              <div class="p-3 d-flex align-items-center">
                <input type="radio" name="type" v-model="user.type" value="external" class="d-none">
                <i class="icon-radio-checked2 text-success mr-2" v-if="user.type == 'external'" />
                <i class="icon-radio-unchecked mr-2" v-else />
                <div>
                  <div class="font-weight-bold">{{ $t('common.external') }}</div>
                  <div>{{ $t('common.external_desc') }}</div>
                </div>
              </div>
            </label>
          </div>
          <div class="row">
            <div class="col-md-6">

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.first_name') }}</label>
              <input required v-model="user.first_name" class="form-control" type="text" :placeholder="$t('form.user.first_name')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.last_name') }}</label>
              <input required v-model="user.last_name" class="form-control" type="text" :placeholder="$t('form.user.last_name')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.email') }}</label>
              <input required v-model="user.email" class="form-control" type="email" :placeholder="$t('form.user.email')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.user.password') }}</label>
              <input v-model="user.password" class="form-control" type="password" :placeholder="$t('form.user.password')"/>
              <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.tribes') }}</label>
              <el-select :multiple="true" :options="worldTribes" v-model="user.tribes" :placeholder="$t('form.user.tribes')" />

              <label class="mt-2 mb-1 font-weight-bold">{{ $t('form.user.position') }}</label>
              <input v-model="user.position" class="form-control" type="text" :placeholder="$t('form.user.position')"/>

            </div>
          </div>

        </div>

        <div class="card-footer">
          <button v-if="!loadingProfile" class="btn btn-lg btn-success text-white">{{ $t('common.submit') }}</button>
          <div v-if="loadingProfile" class="bg-white p-2">
            <span class="text-grey"><loader /></span>
          </div>
        </div>

      </div>
    </form>

  </auth-content>
</template>

<script>

import _ from 'lodash'
import store from '../../store'

import {
  WORLD_USER_ADD,
} from '../../store/mutations-types'

export default {
  name: 'UserProfileForm',
  data () {
    return {
      currentLanguage: null,
      loadingProfile: false,
      user: {
        type: 'internal',
      },
      global: false,
    }
  },
  methods: {
    createUser () {
      this.loadingProfile = true

      let userData = _.cloneDeep(this.user)

      userData.worldId = this.$route.params.world
      userData.internal = (this.user.type == 'internal')

      this.$store.dispatch('world/' + WORLD_USER_ADD, userData).then((user) => {

        this.loadingProfile = false
        this.notifSuccess(this.$t('form.user.user_add_success'))

        this.$router.push({name: 'world.users', params: {world: this.$route.params.world}})

      })
      .catch(error => {
        this.loadingProfile = false
        this.notifError(error)
      })
    },
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale.split('-')[0].toUpperCase()
    },
    worldTribes () {
      let tribesList = []
      _.map(this.$store.getters['world/getTribes'], (elem) => {
        tribesList.push({
          avatar:  elem.slug ? require('@/assets/images/categories/' + elem.slug + '.svg') : elem.avatar.thumb,
          value: elem.id,
          title: elem.name,
        })
      })
      return tribesList
    },
  }
}
</script>

<style lang="scss" scoped>
.bordered-wide {
  border-width: 2px !important;
}
.type-label {
  &:hover {
    background: rgba(black, .03);
  }
}
</style>
